@import url('./variables.css');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
*,
html,
body {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

@font-face {
    font-family: 'DK Frozen Memory';
    src: url('../assets/fonts/DK\ Frozen\ Memory.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body,
html {
    -webkit-tap-highlight-color: transparent;
    font-family: 'Varela Round', sans-serif;
    font-weight: 400;
    background: var(--background-color);
}

h1,
h2,
.logo {
    font-family: 'DK Frozen Memory', sans-serif;
}

p,
li,
h3,
h4,
nav,
input,
select,
textarea,
button {
    font-family: 'Varela Round', sans-serif;
    font-weight: normal;
}

button {
    user-select: none;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
    background: none;
    border: none;
    outline: none;
}